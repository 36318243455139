import React from "react"
import styled from "styled-components"
import Img from "gatsby-image"
import { useStaticQuery, graphql, Link } from "gatsby"
import { FormattedMessage } from "react-intl"
import { RichText } from "prismic-reactjs"

import useLangs from "../../hooks/useLangs"
import { StyledContainer } from "../containers/index"

const StyledBenefits = styled(StyledContainer)`
  margin: 80px auto;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 80px;

  @media (max-width: ${props => props.theme.breakpoints.m}) {
    grid-gap: 32px;
    margin: 40px auto;
  }
`

const StyledBenefit = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 40px;

  .content {
    padding: 40px;
  }

  h2 {
    margin-bottom: 24px;
    line-height: 56px;
  }

  a {
    text-decoration: none;
  }

  @media (max-width: ${props => props.theme.breakpoints.m}) {
    grid-template-columns: 1fr;

    .content {
      padding: 0 0 24px;
    }

    .img-first {
      order: 2;
    }

    .first {
      order: 1;
      padding-top: 0;
    }
  }
`

const StyledButton = styled(Link)`
  display: inline-block;
  margin-top: 24px;
  background-color: ${props => props.theme.colors.primary};
  font-weight: 700;
  padding: 16px 72px;
  border-radius: 4px;
  color: ${props => props.theme.colors.light100};
  text-align: center;
  transition: all 0.2s ease;
  box-shadow: 0 12px 25px rgba(206, 50, 50, 0.2);

  :hover {
    background-color: #ad1f1f;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.s}) {
    width: 100%;
  }
`

const Benefits = ({ data, location }) => {
  const { left, right, site } = useStaticQuery(graphql`
    {
      left: file(relativePath: { eq: "homepage/card-content.png" }) {
        childImageSharp {
          fluid(maxWidth: 560, quality: 30) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      right: file(relativePath: { eq: "homepage/factory.jpeg" }) {
        childImageSharp {
          fluid(maxWidth: 560, quality: 30) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      site {
        siteMetadata {
          languages {
            langs
            defaultLangKey
          }
        }
      }
    }
  `)

  const { localLink } = useLangs(location.pathname, site.siteMetadata.languages)

  return (
    <StyledBenefits>
      <StyledBenefit>
        <Img
          fluid={left.childImageSharp.fluid}
          alt="Hydraulic"
          className="img-first"
        />
        <div className="content first">
          <h2>{data.title_left_side.text}</h2>
          <RichText render={data.text_left_side.raw} />
          <StyledButton to={`${localLink}machinery/transfer-presses`}>
            <FormattedMessage id="capabilities" />
          </StyledButton>
        </div>
      </StyledBenefit>
      <StyledBenefit>
        <div className="content second">
          <h2>{data.title_right_side.text}</h2>
          <RichText render={data.text_right_side.raw} />
          <StyledButton to={`${localLink}about`}>
            <FormattedMessage id="readMore" />
          </StyledButton>
        </div>
        <Img fluid={right.childImageSharp.fluid} alt="Hydraulic" />
      </StyledBenefit>
    </StyledBenefits>
  )
}

export default Benefits
