import React from "react"
import Img from "gatsby-image"
import styled from "styled-components"
import Swiper from "react-id-swiper"
import "swiper/css/swiper.min.css"
import { useStaticQuery, graphql } from "gatsby"

const StyledHero = styled.div`
  max-width: 1224px;
  padding: 0 12px;
  margin: 0 auto;

  img {
    margin-bottom: 0;
  }
`

const Hero = () => {
  const data = useStaticQuery(graphql`
    {
      prismicCarouselGallery {
        data {
          image_gallery {
            image {
              alt
              localFile {
                id
                childImageSharp {
                  fluid(maxWidth: 980, quality: 30) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
        }
      }
    }
  `)
  const params = {
    effect: "fade",
    grabCursor: true,
    pagination: {
      el: ".swiper-pagination",
      clickable: true,
    },
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    },
    autoplay: {
      delay: 5000,
      disableOnInteraction: false,
    },
  }
  return (
    <StyledHero>
      <Swiper {...params}>
        {data.prismicCarouselGallery.data.image_gallery.map(
          ({ image: { alt, localFile } }) => {
            return (
              <Img
                fluid={localFile.childImageSharp.fluid}
                alt={alt}
                key={localFile.id}
              />
            )
          }
        )}
      </Swiper>
    </StyledHero>
  )
}

export default Hero
