import React from "react"
import styled from "styled-components"
import Img from "gatsby-image"
import { Link, useStaticQuery, graphql } from "gatsby"
import { FormattedMessage } from "react-intl"

import useLangs from "../../hooks/useLangs"
import ArrowIcon from "../../images/icons/arrow-icon.inline.svg"
import { StyledContainer } from "../containers/index"

const StyledProductsGrid = styled.div`
  display: grid;
  grid-gap: 24px;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));

  a {
    text-decoration: none;

    :hover {
      svg {
        transform: translateX(12px);
      }
    }
  }

  p {
    padding: 16px;
    font-weight: 500;
    color: ${props => props.theme.colors.dark100};
    background: #ccc;
    display: flex;
    justify-content: center;
  }

  svg {
    transition: all 0.2s ease;
    transform: translateX(8px);
  }
`

const Products = ({ location }) => {
  const data = useStaticQuery(graphql`
    {
      site {
        siteMetadata {
          languages {
            langs
            defaultLangKey
          }
        }
      }
      first: file(relativePath: { eq: "homepage/small1.png" }) {
        childImageSharp {
          fluid(maxWidth: 320, quality: 30) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      second: file(relativePath: { eq: "homepage/small2.png" }) {
        childImageSharp {
          fluid(maxWidth: 320, quality: 30) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      third: file(relativePath: { eq: "homepage/small3.png" }) {
        childImageSharp {
          fluid(maxWidth: 320, quality: 30) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      fourth: file(relativePath: { eq: "homepage/small4.png" }) {
        childImageSharp {
          fluid(maxWidth: 320, quality: 30) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  const { localLink } = useLangs(
    location.pathname,
    data.site.siteMetadata.languages
  )

  return (
    <div style={{ backgroundColor: "#f3f4f9", padding: "80px 0" }}>
      <StyledContainer>
        <StyledProductsGrid>
          <Link to={`${localLink}products/transfer-parts`}>
            <Img fluid={data.first.childImageSharp.fluid} />
            <p>
              <FormattedMessage id="viewMore" />
              <ArrowIcon />
            </p>
          </Link>
          <Link to={`${localLink}products/progressive-parts`}>
            <Img fluid={data.second.childImageSharp.fluid} />
            <p>
              <FormattedMessage id="viewMore" />
              <ArrowIcon />
            </p>
          </Link>
          <Link
            to={`${localLink}products/single-operation-and-hydraulic-pressing-parts`}
          >
            <Img fluid={data.third.childImageSharp.fluid} />
            <p>
              <FormattedMessage id="viewMore" />
              <ArrowIcon />
            </p>
          </Link>
          <Link to={`${localLink}products/nc-machining-parts`}>
            <Img fluid={data.fourth.childImageSharp.fluid} />
            <p>
              <FormattedMessage id="viewMore" />
              <ArrowIcon />
            </p>
          </Link>
        </StyledProductsGrid>
      </StyledContainer>
    </div>
  )
}

export default Products
